import 'datatables.net';
import 'datatables.net-bs4';
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.colVis.min';
import 'datatables.net-buttons/js/buttons.flash.min';
import 'datatables.net-buttons/js/buttons.html5.min';
import 'datatables.net-buttons/js/buttons.print.min';
import 'datatables.net-buttons-bs4';

const datatableLoad = () => {
  $('.datatable').dataTable({
    dom: 'Bfrtip',
    buttons: [
      'excel', 'pdf',
      // 'copy', 'csv', 'excel', 'pdf', 'print',
    ],
    language: {
      processing: '處理中...',
      loadingRecords: '載入中...',
      lengthMenu: '顯示 _MENU_ 項結果',
      zeroRecords: '沒有符合的結果',
      info: '顯示第 _START_ 至 _END_ 項結果，共 _TOTAL_ 項',
      infoEmpty: '顯示第 0 至 0 項結果，共 0 項',
      infoFiltered: '(從 _MAX_ 項結果中過濾)',
      infoPostFix: '',
      search: '搜尋:',
      paginate: {
        first: '第一頁',
        previous: '上一頁',
        next: '下一頁',
        last: '最後一頁',
      },
      aria: {
        sortAscending: ': 升冪排列',
        sortDescending: ': 降冪排列',
      },
    },
  });
};
window.datatableLoad = datatableLoad;
$(datatableLoad);
