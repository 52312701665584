$(document).ready(() => {
  for (let i = 0; i <= 2; i++) {
    $(`.parent-ch-${i}`).first().change(function() {
      if (this.checked) {
        $(`.child-ch-${i}`).attr("checked", true);
        $(`.child-ch-${i}`).prop("checked", true);
      } else {
        $(`.child-ch-${i}`).attr("checked", false);
        $(`.child-ch-${i}`).prop("checked", false);
      }
    })
  }
})
