/* eslint-disable no-new */
import Sortable from 'sortablejs';

const callReorderApi = ({ url, ids }) => {
  $.ajax({
    url,
    type: 'PATCH',
    dataType: 'script',
    data: {
      ids,
      authenticity_token: $('[name="csrf-token"]')[0].content,
    },
  });
};

const rerenderList = ({ children, tdIndex = 0 }) => {
  children.forEach((child, index) => {
    child.children[tdIndex].innerHTML = index + 1;
  });
};

$(document).ready(() => {
  $('.sortable').each((id, item) => {
    new Sortable(item, {
      onUpdate(event) {
        const children = [...event.to.children];
        const ids = children.map((el)=> el.getAttribute('data-id'));

        // children.forEach((child, index) => {
        //   child.children[0].innerHTML = index + 1;
        // });

        // console.log(ids);
        // console.log(`${event.oldIndex} > ${event.newIndex}`)

        if ($('.c-home_slides.a-index').length) {
          callReorderApi({ url: '/home_slides/reorder', ids });
        } else if ($('.c-hot_videos.a-index').length) {
          callReorderApi({ url: '/hot_videos/reorder', ids });
        } else if ($('.c-members_onlies.a-index').length) {
          callReorderApi({ url: '/members_onlies/reorder', ids });
        } else if ($('.c-recommends.a-index').length) {
          callReorderApi({ url: '/recommends/reorder', ids });
        } else if ($('.c-magazines.a-index').length) {
          callReorderApi({ url: '/magazines/reorder', ids });
          rerenderList({ children });
        } else if ($('.c-menus.a-index').length) {
          callReorderApi({ url: '/menus/reorder', ids });
        } else if ($('.c-channels.a-index').length) {
          callReorderApi({ url: '/channels/reorder', ids });
        } else if ($('.c-channels.a-show').length) {
          callReorderApi({ url: '/channel_slides/reorder', ids });
          rerenderList({ children, tdIndex: 1 });
        } else if ($('.c-specials.a-index').length) {
          callReorderApi({ url: '/specials/reorder', ids });
          rerenderList({ children, tdIndex: 0 });
        } else if ($('.c-admins.a-index').length) {
          callReorderApi({ url: '/admins/reorder', ids });
        }
      },
    });
  });
});
