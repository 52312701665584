const FroalaEditor = require('froala-editor')
require('froala-editor/js/plugins.pkgd.min')
require('froala-editor/js/third_party/embedly.min')
require('admin/js/plugins/languages/froala/zh_tw.js')
const anchorImg = require('./images/icon_anchor_3x.png')
const anchorLinkImg = require('./images/icon_anchor_link_3x.png')

var ANCHORS = [] // 锚点列表
function insertStyle() {
  let styleElement = document.createElement('style')
  styleElement.innerHTML = `

        .toc-item-anchor:empty {
          cursor: default;
          display: inline-block;
          height: 10px !important;
          width: 10px !important;
          -webkit-user-modify: read-only;
          -moz-user-modify: read-only;
          -webkit-user-select: none;
          -moz-user-select: none;
          user-select: none;
        }
        .toc-item-anchor:empty::before {
          content: "\\2693";
          font-size:10px;
          color: "#4caf50";
        }
        .anchors-ctx{
          /*background-color: rgba(0, 0, 0, 0.8);*/
          background-color:#2f3640;
          padding: 10px 15px;
          min-width: 200px;
          max-height: 500px;
          border-radius: 4px;
          color: #fff;
          z-index: 99;
          position: fixed;
          top: 30%;
          left: 50%;
          transform: translateX(-50%);
          overflow: auto;
        }
        .anchors-ctx-title{
          padding: 0 0 10px 0;
        }
        .select{
          background-color: #f5f5f5;
          padding: 8px 1em 5px 5px;
          margin: 0;
          width: 100%;
          line-height: inherit;
          border: none;
          outline: none;
          border-radius: 4px;
        }
        .anchors-btn{
          width: 100%;
          margin: 10px auto;
          border-radius: 4px;
          padding: 6px 0 8px 0;
          outline: none;
          background-color: #f08f7a;
          border-color: #f08f7a;
          color: white;
        }
        `
  document.head.appendChild(styleElement)
}

// 获取所有锚点元素的id
function extractAnchorIds(htmlString) {
  try {
    const div = document.createElement('div')
    div.innerHTML = htmlString
    const anchorTags = div.querySelectorAll('a[anchor="true"]')
    const anchorIds = []
    anchorTags.forEach(tag => {
      anchorIds.push(tag.id)
    })
    return anchorIds
  } catch (err) {
    console.warn(err)
    return []
  }
}

/**
 * 删除锚点的时候使用
 * 替换指定 href 属性值的 <h4> 标签为 <p> 标签
 * @param {string} hrefValue - 需要匹配的 href 属性值
 */
function replaceH4WithP(hrefValue, htmlCtx) {
  const container = document.createElement('div')
  container.innerHTML = htmlCtx
  const h4Elements = container.querySelectorAll('h4')
  h4Elements.forEach(h4 => {
    const aTag = h4.querySelector(`a[href="${hrefValue}"]`)
    if (aTag) {
      const pTag = document.createElement('p')
      pTag.textContent = h4.textContent
      h4.replaceWith(pTag)
    }
  })
  // 删除空的 <p> 标签
  container.querySelectorAll('p').forEach(p => {
    if (p.innerHTML.trim() === '') {
      p.remove()
    }
  })
  return container.innerHTML
}
insertStyle()
$(document).ready(() => {
  if ($('.c-articles.a-new').length || $('.c-articles.a-edit').length) {
    var autoSaveTimer = null

    $('#article_member_only').change(() => {
      if ($('#article_member_only').is(':checked')) {
        $('.article_member_only_image').show()
      } else {
        $('.article_member_only_image').hide()
      }
    })
    $('#article_member_only').trigger('change')

    $('#article_article_category_ids').change(() => {
      if ($('#article_article_category_ids option').filter(':selected').text() == '體驗') {
        $('#article-event').show()
      } else {
        $('#article-event').hide()
      }
    })
    $('#article_article_category_ids').trigger('change')

    $('#article_apply_homepage_carousel').change(() => {
      if ($('#article_apply_homepage_carousel').is(':checked')) {
        $('.article_home_slide_image').show()
      } else {
        $('.article_home_slide_image').hide()
      }
    })
    $('#article_apply_homepage_carousel').trigger('change')

    const updateChildPathOptions = selectOptions => {
      const $children = $('#article_child_path')
      $children.html(selectOptions).selectpicker('refresh')
    }

    $('#article_parent_path').on('change', e => {
      const slug = e.target.value
      if (slug) {
        fetch(`/sub_channel_list/${slug}`)
          .then(r => r.json())
          .then(json => {
            const selectOptions = json.map(arr => `<option value="${arr[1]}">${arr[0]}</option>`).join()
            updateChildPathOptions(selectOptions)
          })
      } else {
        updateChildPathOptions('')
      }
    })

    $('#upper-save').click(function () {
      $('input[type=submit][name=save]').trigger('click')
    })

    let preSelectedReferenceId = JSON.parse($('input[name=preSelectedReferenceId]').val())

    $.each(preSelectedReferenceId, function (index, value) {
      $('.article-reference-ajax').append(new Option(value.title, value.id, false, true)).trigger('change')
    })
  }

  // 关联锚点的选择器弹窗
  class CustomDialog {
    constructor() {
      this.dialogContainer = null
      this.confirmCallBack = null
    }

    closeDialog = e => {
      if (!this.dialogContainer.contains(e.target)) {
        document.body.removeChild(this.dialogContainer)
        document.removeEventListener('click', this.closeDialog)
      }
    }

    showCustomDialog = callback => {
      this.confirmCallBack = callback
      this.dialogContainer = document.createElement('div')
      let innerHTML = `
          <div class="anchors-ctx">
            <div class="anchors-ctx-title">選擇錨點</div>
            <div class="opt">
                <select class="select" id="customDropdown">`
      ANCHORS.forEach(item => {
        innerHTML += `
                  <option class="anchors-option"  value="${item}">${item}</option>
                  `
      })
      innerHTML += `</select>
            </div>
            <button
              id="cfbtn"
              class="anchors-btn"
            >
              確定
            </button>
          </div>
          `
      this.dialogContainer.innerHTML = innerHTML
      document.body.appendChild(this.dialogContainer)
      document.addEventListener('click', this.closeDialog)
      this.dialogContainer.querySelector('#cfbtn').addEventListener('click', this.confirm)
    }

    confirm = () => {
      let selectElement = document.getElementById('customDropdown')
      let selectedValue = selectElement.options[selectElement.selectedIndex].value
      document.body.removeChild(this.dialogContainer)
      document.removeEventListener('click', this.closeDialog)
      this.confirmCallBack(selectedValue)
    }
  }

  // 文字图标
  // FroalaEditor.DefineIcon('insertAnchor', {
  //   template: 'text',
  //   NAME: '插入锚点'
  // })
  // 自定义菜单  --插入锚点
  FroalaEditor.DefineIcon('insertAnchor', {
    template: 'image',
    SRC: anchorImg
  })
  FroalaEditor.RegisterCommand('insertAnchor', {
    title: '插入錨點',
    focus: false,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      if (this.selection.text().length <= 0) {
        alert('請先選中段落內容')
        return
      }
      let anchorName = prompt('請輸入錨點標識（例如 test_01 ）')
      if (anchorName) {
        ANCHORS.push(anchorName)
        let selectedElement = this.selection.element()
        let anchorTag = document.createElement('a')
        anchorTag.setAttribute('id', anchorName)
        anchorTag.setAttribute('contenteditable', false)
        anchorTag.setAttribute('anchor', true)
        anchorTag.setAttribute('class', 'toc-item-anchor')
        selectedElement.parentNode.insertBefore(anchorTag, selectedElement)
      }
    }
  })
  // 自定义菜单 链接锚点
  FroalaEditor.DefineIcon('linkAnchor', {
    template: 'image',
    SRC: anchorLinkImg
  })
  FroalaEditor.RegisterCommand('linkAnchor', {
    title: '鏈接錨點',
    focus: false,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      // 从编辑器已有的内容取锚点(如果有)
      const ids = extractAnchorIds(this.html.get())
      if (ids.length) ANCHORS = ids
      if (ANCHORS.length < 1) {
        alert('請先設定錨點')
        return
      }
      if (this.selection.text().length < 1) {
        alert('請選擇目錄')
        return
      }

      new CustomDialog().showCustomDialog(data => {
        if (data) {
          // 创建a标签
          let anchorTag = document.createElement('a')
          anchorTag.setAttribute('href', `#${data}`)
          anchorTag.textContent = this.selection.text()
          let h4Tag = document.createElement('h4')
          h4Tag.appendChild(anchorTag)
          this.html.insert(h4Tag.outerHTML)
        }
      })
    }
  })

  FroalaEditor('#froala-editor', {
    key: '9KH3cA3D4E4E3C2F3zkgfaD6ecH5lC-7G-7B2gciG5G4E3C3A1B8D6D4A3D4==',
    heightMin: 200,
    language: 'zh_tw',
    htmlAllowedEmptyTags: [
      'textarea',
      'a',
      'iframe',
      'object',
      'video',
      'style',
      'script',
      '.fa',
      'span',
      'p',
      'path',
      'line'
    ],
    htmlAllowedTags: ['.*'],
    htmlAllowedAttrs: ['.*'],
    htmlRemoveTags: ['script', 'base'],
    imageUploadURL: '/uploads',
    imageUploadMethod: 'POST',
    imageAllowedTypes: ['gif', 'jpeg', 'jpg', 'png', 'svg'],
    imageDefaultWidth: 600,
    // imageMaxSize: 1024 * 1024 * 10,  // default 10MB
    requestHeaders: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    },
    toolbarButtons: {
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          'strikeThrough',
          'subscript',
          'superscript',
          'fontFamily',
          'fontSize',
          'textColor',
          'backgroundColor',
          'inlineClass',
          'inlineStyle',
          'clearFormatting'
        ]
      },
      moreParagraph: {
        buttons: [
          'alignLeft',
          'alignCenter',
          'formatOLSimple',
          'alignRight',
          'alignJustify',
          'formatOL',
          'formatUL',
          'paragraphFormat',
          'paragraphStyle',
          'lineHeight',
          'outdent',
          'indent',
          'quote',
          'linkAnchor',
          'insertAnchor'
        ]
      },
      moreRich: {
        buttons: [
          'insertLink',
          'insertImage',
          'insertVideo',
          'insertTable',
          'emoticons',
          'fontAwesome',
          'specialCharacters',
          'embedly',
          'insertFile',
          'insertHR'
        ]
      },
      moreMisc: {
        buttons: ['undo', 'redo', 'fullscreen', 'print', 'spellChecker', 'selectAll', 'html', 'help'],
        align: 'right',
        buttonsVisible: 2
      }
    },
    paragraphFormat: {
      N: 'Normal',
      // H1: 'Heading 1',
      H2: 'Heading 2',
      H3: 'Heading 3',
      H4: 'Heading 4',
      PRE: 'Code'
    },
    linkInsertButtons: [],
    linkStyles: {
      extended: '設定延伸閱讀'
    },
    events: {
      contentChanged: function () {
        $('.fr-box.fr-basic .fr-element.fr-view a').css('color', '#F08F7A')
      },
      keydown: function () {
        clearTimeout(autoSaveTimer)
      },
      keyup: function () {
        const articleId = $('input[name=articleId]').val()
        if (articleId) {
          autoSaveTimer = setTimeout(() => {
            $('input[type=submit][name=auto_save]').trigger('click')
          }, 5000)
        }
      },
      // 删除锚点/取消链接时触发
      'link.beforeRemove': function (link) {
        const id = link.getAttribute('id') // 获取 id 属性
        const href = link.getAttribute('href') // 获取 href 属性
        const anchor = link.getAttribute('anchor') // 获取自定义属性 anchor
        // 是锚点
        if (id && anchor) {
          const that = this
          setTimeout(() => {
            const newHtml = replaceH4WithP(`#${id}`, that.html.get())
            if (newHtml) that.html.set(newHtml)
          }, 200)
        }
        if (href && href.includes('#')) {
          const that = this
          const newHtml = replaceH4WithP(href, that.html.get())
          setTimeout(() => {
            if (newHtml) that.html.set(newHtml)
          }, 200)
        }
      }
    }
  })
})
