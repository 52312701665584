$(document).ready(() => {
  if ($('.simple_form.batch').length) {
    $('.simple_form.batch').bind('keypress', (e) => {
      if (e.keyCode == 13) {
        return false;
      }
    });
    const handleBatchActions = function () {
      if($("[name$='[ids][]']:checked").length){
        $('.batch-actions').show()
      } else {
        $('.batch-actions').hide()
      }
    };
    $("[name$='[ids][]']").on('change', handleBatchActions);
  }
});
