$(document).ready(() => {
  const updatePreview = function() {
    const input = this;
    if (input.files && input.files[0]) {
      if (input.files.length === 1) {
        const reader = new FileReader();
        reader.onload = (e) => {
          console.log(`#preview_${this.id}`);
          $(`#preview_${this.id}_old`).attr('src', e.target.result).hide();
          $(`#preview_${this.id}`).attr('src', e.target.result).show();
        };
        reader.readAsDataURL(input.files[0]);
      } else {
        const previewBlock = $(this).closest('.item').find('#preview-images');
        previewBlock.html('');
        let i;
        for (i = 0; i < input.files.length; i += 1) {
          const file = input.files[i];
          const reader = new FileReader();
          reader.onload = (e) => {
            const img = $('<img class="rounded img-raised" height="150">').attr('src', e.target.result);
            previewBlock.append(img);
          };
          reader.readAsDataURL(file);
        };
      }
    }
  };

  $('form').on('change', 'input.file.previewable', updatePreview);
});
