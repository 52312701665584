import 'select2';
import 'select2/dist/js/i18n/zh-TW';

$(document).ready(() => {
  $('.select2-tags:not(.not-create)').select2({
    tags: true,
    width: '100%',
    theme: 'bootstrap4',
    language: 'zh-TW',
  });
  $('.select2-tags.not-create').select2({
    tags: true,
    width: '100%',
    theme: 'bootstrap4',
    language: 'zh-TW',
    createTag: () => {},
  });
  $('.select2-tag-list-ajax').each(function () {
    const url = '/tag_list';
    const maximumSelectionLength = $(this).data('limit') || 0;

    $(this).select2({
      maximumSelectionLength,
      tags: true,
      multiple: true,
      placeholder: '請選擇',
      allowClear: true,
      width: '100%',
      theme: 'bootstrap4',
      language: 'zh-TW',
      ajax: {
        url,
        delay: 250,
        dataType: 'json',
        data: ({ term, page }) => ({
          title: term,
          page: page || 1,
        }),
        processResults: (data, params) => {
          params.page = params.page || 1;
          return {
            results: data.items.map(({ name }) => ({ id: name, text: name })),
            pagination: {
              more: (params.page * 5) < data.total_count,
            },
          };
        },
      },
    });
  });

  $('.article-reference-ajax').each(function () {
    const url = $(this).data('url');
    const placeholder = $(this).data('placeholder');
    const maximumSelectionLength = $(this).data('limit') || 0;
    const exclude_id = $(this).data('exclude') || '';

    $(this).select2({
      maximumSelectionLength,
      multiple: true,
      placeholder: `${placeholder}`,
      allowClear: true,
      width: '100%',
      theme: 'bootstrap4',
      language: 'zh-TW',
      ajax: {
        url,
        delay: 250,
        dataType: 'json',
        data: ({ term, page }) => ({
          article: {
            keyword: term,
            status: 'publishing',
            exclude_id: exclude_id,
            channel_ids: $('.article_reference_channel_ids:checkbox:checked').map((_, e) => e.value).get(),
            author_query: {
              mode: $('#article_reference_author_query').val(),
              user_id: $('#article_reference_author_query_user_id option:selected').map((_, e) => e.value).get(),
            },
            page: page || 1,
          },
        }),
        processResults: (data, params) => {
          params.page = params.page || 1;

          return {
            results: data.items.map(({ id, title }) => ({ id, text: title })),
            pagination: {
              more: (params.page * 100) < data.total_count,
            },
          };
        },
      },
    });
  });

});
